<template>
  <Field
    :id="props.id"
    v-slot="{ handleChange, errors }"
    v-model="modelValue"
    :name="props.name"
    :rules="props.rules"
  >
    <UiSelect
      :id="props.id"
      v-model:selected="modelValue"
      v-bind="$attrs"
      :label="props.label"
      :placeholder="props.placeholder"
      :label-visible="props.labelVisible"
      :errors="errors"
      :small="props.small"
      :container-class="props.containerClass"
      :disabled="props.disabled"
      :variant="props.variant"
      @change="handleChange"
    >
      <template
        v-for="(_, name) in $slots"
        #[name]="slotData"
      >
        <slot
          :name="name"
          v-bind="slotData"
        />
      </template>
    </UiSelect>

    <slot
      v-if="props.showValidationMessageOutsideInput"
      name="error"
      :errors="errors"
    >
      <UiFieldMsg
        :errors="errors"
        :additional-msg="additionalMsg"
      />
    </slot>
  </Field>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { Field } from 'vee-validate'
import UiFieldMsg from '../UiFieldMsg/UiFieldMsg.vue'
import UiSelect from '../../UiSelect/UiSelect.vue'
import type { UiFormFieldSelectProps } from './UiFormFieldSelect.types'

const props = withDefaults(defineProps<UiFormFieldSelectProps>(), {
  id: '',
  label: '',
  placeholder: '',
  name: '',
  rules: '',
  additionalMsg: '',
  showValidationMessageOutsideInput: true,
  labelVisible: false,
  small: false,
  selected: null,
  variant: 'default',
  containerClass: () => ['ui-form-field-select'],
})

const $emit = defineEmits(['input'])
const modelValue = ref(props.selected)
watch(modelValue, (newValue) => {
  const value = newValue
  $emit('input', value)
})
</script>
